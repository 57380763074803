import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

const DocumentDownload = (props) => {
  const { row, handleSelfieDownload, selfieLoader, uniqueId } = props;
  const [updatedSelfieLink, setUpdatedSelfieLink] = useState();

  // const handleClick = (row) => {
  //   handleSelfieDownload(row.original.selfieLink, row.original);
  // };

  function replaceExtension(url) {
    // Regular expression to match URLs with extensions
    var pattern = /^(.*)(\.(jpg|jpeg|png|gif))$/;
    var match = url.match(pattern);

    if (match) {
      return url;
    } else {
      return url + "jpg";
    }
  }

  useEffect(() => {
    if (
      (uniqueId === "selfieId" && !row.original.selfieLink) ||
      (uniqueId === "checkoutSelfieLinkId" && !row.original.checkoutSelfieLink) ||
      (uniqueId === "overTime" && !row.original.overtimeSelfieLink)
    )
      return;
    const replacedLink = replaceExtension(
      uniqueId === "selfieId"
        ? row.original.selfieLink
        : (uniqueId === "overTime" ? row.original.overtimeSelfieLink : row.original.checkoutSelfieLink)
    );
    setUpdatedSelfieLink(replacedLink);
  }, []);

  return (uniqueId === "selfieId" && row.original.selfieLink) ||
    (uniqueId === "checkoutSelfieLinkId" && row.original.checkoutSelfieLink) ||
    (uniqueId === "overTime" && row.original.overtimeSelfieLink) ? (
    <div style={{ textAlign: "center", width: "87px" }}>
      {selfieLoader === row.original.attendanceId ? (
        <CircularProgress />
      ) : (
        <a href={updatedSelfieLink && updatedSelfieLink} target="_blank" rel="noreferrer">
          Download
        </a>
      )}
    </div>
  ) : (
    <div style={{ width: "87px" }}>-</div>
  );
};

export default DocumentDownload;
